.menu {
  position: fixed;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1rem;
}

.menu ul {
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
}

.menu ul li {
  cursor: pointer;
}
