html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

p {
  line-height: 2rem;
}

body {
  color: #fff;
  height: 100%;
  position: relative;
  background-image: url('backen.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left top;
  margin: 0;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
  padding: 0;
  overflow-x: hidden;
}

.page {
  min-height: 100vh;
}

.page-about {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9db8bd;
  color: #000;
}

.page-about img {
  max-width: 80%;
}

.page-about li {
  margin-right: 0.5rem;
  display: inline-block;
}

.page-about a {
  font-size: 2rem;
}

.page-projects img {
  max-height: 100vh;
}

.page-tech-stack {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #525d53;
}

.carousel-caption p,
.carousel-caption h3 {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.8);
}

.page-projects {
  background-color: #333;
}

a {
  color: rgba(255, 255, 255, 0.7);
}

a:hover {
  color: rgba(255, 255, 255, 1);
}
