.page-projects {
  position: relative;
}

.carousel {
  height: 100vh;
  display: flex;
  align-items: center;
  max-width: 2000px;
  margin: auto;
}

.header {
  display: none;
  position: absolute;
  top: 0px;
  padding: 1rem 2rem;
}

@media screen and (max-width: 600px) {
  .carousel-caption {
    position: static !important;
    font-size: 0.9rem;
  }

  .carousel-caption h3 {
    font-size: 1.4rem;
  }
}
